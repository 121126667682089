import React from "react";

import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Grid from "../../../lib/components/grid";
import { GridColumns } from "./gridsetup";
import { exportProducerIntakeAndShipments, exportProducerPaymentsSummaryReport, getProducerIntakeAndShipments } from "../../../lib/api/producersfull";
import { getFinancialYearSelected } from "../../../lib/api/week";
import { DocumentsButton, DocumentsType } from "../../../lib/components/DocumentsButton";

import Toolbar from "@material-ui/core/Toolbar";

import format from "date-fns/format";

import { getProducerByProducerCode } from "../../../lib/api/producer";
import { GenerateErrorMessage } from "../../../lib/helpers/string_methods";
import { SnackContext } from "../../../lib/context/SnackContext";
import { LinearProgress } from "@material-ui/core";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    gridWrapper: {
      position: "relative",
    },
    toolbar: {
      position: "absolute",
      left: 0,
      top: 0,
      height: "60px",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      gap: "10px",
    },
  });

type Props = {
  match: any;
} & WithStyles<typeof styles>;

class IntakesAndShipmentsUnstyled extends React.Component<Props, {}> {
  constructor(props) {
    super(props);
    this.state.producer_id = props.match.params.producerid;
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  state = {
    loading: true,
    data: [],
    producer_id: undefined,
    reportLoading: false,
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    this.setState({ loading: true });
    const data = await getProducerIntakeAndShipments(this.state.producer_id, getFinancialYearSelected());
    this.setState({ loading: false, data: data });
  };

  handleExport = async (shouldIncludeShipments = true) => {
    this.setState({ reportLoading: true });
    try {
      // YYMMDD_PRODUCERNAME_PRODUCER PAYMENT SUMMARY
      const producerCode = this.state.producer_id.includes("BASE") ? this.state.producer_id.replace("BASE", "001") : this.state.producer_id;
      const producer = await getProducerByProducerCode(producerCode);

      const filename = shouldIncludeShipments
        ? `${format(new Date(), "yyMMdd")}_${producer.data[0].name.replaceAll(" ", "_")}_ALL_INTAKES_AND_SHIPMENTS`
        : `${format(new Date(), "yyMMdd")}_${producer.data[0].name.replaceAll(" ", "_")}_ALL_INTAKES`;

      await exportProducerIntakeAndShipments(this.state.producer_id, getFinancialYearSelected(), shouldIncludeShipments, filename);
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error printing report");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }
    this.setState({ reportLoading: false });
  };

  handleExportSummary = async () => {
    this.setState({ reportLoading: true });
    try {
      // YYMMDD_PRODUCERNAME_PRODUCER PAYMENT SUMMARY
      const producerCode = this.state.producer_id.includes("BASE") ? this.state.producer_id.replace("BASE", "001") : this.state.producer_id;
      const producer = await getProducerByProducerCode(producerCode);
      const filename = `${format(new Date(), "yyMMdd")}_${producer.data[0].name.replaceAll(" ", "_")}_PRODUCER_PAYMENT_SUMMARY`;
      await exportProducerPaymentsSummaryReport(this.state.producer_id, getFinancialYearSelected(), filename);
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error printing report");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }
    this.setState({ reportLoading: false });
  };

  menuItems = [
    {
      title: "ALL INTAKES",
      icon: DocumentsType.EXCEL,
      action: () => this.handleExport(false),
    },
    {
      title: "ALL INTAKES AND SHIPMENTS",
      icon: DocumentsType.EXCEL,
      action: () => this.handleExport(),
    },
    {
      title: "PRODUCER PAYMENT SUMMARY",
      icon: DocumentsType.EXCEL,
      action: () => this.handleExportSummary(),
    },
  ];

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.gridWrapper}>
        {this.state.reportLoading && <LinearProgress color="secondary" />}
        <Toolbar className={classes.toolbar}>
          <DocumentsButton menuItems={this.menuItems} disabled={this.state.loading} />
        </Toolbar>
        <Grid
          forceHeight={800}
          loading={this.state.loading}
          data={this.state.data}
          GridColumns={GridColumns}
          clearFilters="intakesandshipmentsgrid"
          totalRowColumns={["bar_NoCartons", "bar_PalletSize", "shippedCartons", "shippedPallets", "prodAdv", "prodFinal"]}
        />
      </div>
    );
  }
}

export default withStyles(styles)(IntakesAndShipmentsUnstyled);
